import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useCommons } from '../CommonContext';
import { useToast } from '../ToastContext';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaLink, FaTag, FaGlobe, FaChartLine } from 'react-icons/fa';
import { fetchLinks, fetchAvailableDomains, fetchTags, deleteLink } from '../api';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import EditLinkModal from './EditLinkModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';

function Home() {
    const { setLoading, refreshLimits, limits } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [recentLinks, setRecentLinks] = useState([]);
    const [domains, setDomains] = useState([]);
    const [tags, setTags] = useState([]);
    const [showEditLinkModal, setShowEditLinkModal] = useState(false);
    const [selectedLinkId, setSelectedLinkId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const hasLoadedHomeData = useRef(false);

    const loadDomainsAndTags = async () => {
        setLoading(true);
        try {
            const domainsResponse = await fetchAvailableDomains();
            setDomains(domainsResponse.data ?? []);
            const tagsResponse = await fetchTags();
            setTags(tagsResponse.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const loadLinksData = async () => {
        setLoading(true);
        try {
            const linksResponse = await fetchLinks({ start: 0, limit: 10 });
            setRecentLinks(linksResponse.data ?? []);
        } catch (error) {
            showErrorToast('Error', 'Failed to fetch dashboard data');
        } finally {
            setLoading(false);
        }
    };

    const refreshHomeData = () => {
        loadLinksData();
        refreshLimits();
    }

    useEffect(() => {
        if (!hasLoadedHomeData.current) {
            loadLinksData();
            loadDomainsAndTags();
            hasLoadedHomeData.current = true;
        }
        return () => {
            hasLoadedHomeData.current = false;
        };
    }, []);

    const handleDeleteConfirm = async () => {
        setLoading(true);
        try {
            await deleteLink(selectedLinkId);
            showSuccessToast('Success', 'Link deleted successfully');
            loadLinksData();
            refreshLimits();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setShowDeleteModal(false);
            setShowEditLinkModal(false);
            setSelectedLinkId(null);
            setLoading(false);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setShowEditLinkModal(true);
    };

    const handleDeleteClick = (id) => {
        setSelectedLinkId(id);
        setShowDeleteModal(true);
        setShowEditLinkModal(false);
    };

    const handleEditClick = (link) => {
        setSelectedLinkId(link.id);
        setShowEditLinkModal(true);
    };

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            showSuccessToast('Copied', 'Link copied to clipboard');
        }).catch(err => {
            showErrorToast('Error', 'Failed to copy link');
        });
    };

    return (
        <>
            <div className="page">
                <div className="section-title">Welcome</div>
                <div className="row mb-4">
                    <div className="col-md-3">
                        <Card className="text-center h-100 section-card">
                            <Card.Body>
                                <FaLink className="dashboard-icon mb-2" />
                                <Card.Title>Links</Card.Title>
                                <h3>{limits && limits.current_links}</h3>
                                <Card.Text>
                                    {limits && `${limits.current_links_this_month} / ${limits.max_links}`}
                                </Card.Text>
                                <Link to="/links">
                                    <Button variant="primary">Manage Links</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="text-center h-100 section-card">
                            <Card.Body>
                                <FaTag className="dashboard-icon mb-2" />
                                <Card.Title>Tags</Card.Title>
                                <h3>{limits && limits.current_tags}</h3>
                                <Card.Text>
                                    {limits && `${limits.current_tags} / ${limits.max_tags}`}
                                </Card.Text>
                                <Link to="/tags">
                                    <Button variant="primary">Manage Tags</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="text-center h-100 section-card">
                            <Card.Body>
                                <FaGlobe className="dashboard-icon mb-2" />
                                <Card.Title>Domains</Card.Title>
                                <h3>{limits && limits.current_domains}</h3>
                                <Card.Text>
                                    {limits && `${limits.current_domains} / ${limits.max_domains}`}
                                </Card.Text>
                                <Link to="/domains">
                                    <Button variant="primary">Manage Domains</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3">
                        <Card className="text-center h-100 section-card">
                            <Card.Body>
                                <FaChartLine className="dashboard-icon mb-2" />
                                <Card.Title>Analytics</Card.Title>
                                <Card.Text>
                                    View your link performance
                                </Card.Text>
                                <Link to="/stats">
                                    <Button variant="primary">View Analytics</Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>

                <Card className="section-card">
                    <Card.Body>
                        <Card.Title>Recent Links</Card.Title>
                        {recentLinks && recentLinks.length > 0 ? (
                            <>
                                <div className="data-list mb-3">
                                    {recentLinks.map(link => {
                                        const domain = domains && domains.length > 0 ? domains.find(d => d.id === link.domainId) : null;
                                        const fullLink = domain ? `https://${domain.name}/${link.slug}` : `/${link.slug}`;
                                        return (
                                            <div key={link.id} className="data-list-item clickable" onClick={() => handleEditClick(link)}>
                                                <div className="mb-3">
                                                    <span>{link.title}</span>
                                                    <div>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={<Tooltip>{link.url}</Tooltip>}
                                                        >
                                                            <a href={fullLink} target="_blank" rel="noopener noreferrer" onClick={(e) => { e.preventDefault(); handleCopyLink(fullLink); }}>
                                                                {fullLink}
                                                            </a>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-wrap gap-1">
                                                    <Badge bg={link.status ? "success" : "secondary"} className="me-2">{link.status ? "Active" : "Inactive"}</Badge>
                                                    {link.hasPassword === true && <Badge bg="warning" className="me-2">Password Protected</Badge>}
                                                    {link.utmSource && <Badge bg="info" className="me-2">UTM Source</Badge>}
                                                    {link.utmMedium && <Badge bg="info" className="me-2">UTM Medium</Badge>}
                                                    {link.utmCampaign && <Badge bg="info" className="me-2">UTM Campaign</Badge>}
                                                    {link.utmTerm && <Badge bg="info" className="me-2">UTM Term</Badge>}
                                                    {link.utmContent && <Badge bg="info" className="me-2">UTM Content</Badge>}
                                                    {link.tags && Object.keys(link.tags).length > 0 ? Object.values(link.tags).map(tag => (
                                                        <Badge bg="secondary" key={tag.id} className="me-1">{tag.name}</Badge>
                                                    )) : null}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : <p>No links available.</p>}
                        <div className="mt-3">
                            <Link to="/links">
                                <Button variant="secondary">View All Links</Button>
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <EditLinkModal
                showModal={showEditLinkModal}
                onCancel={() => setShowEditLinkModal(false)}
                onDelete={handleDeleteClick}
                selectedId={selectedLinkId}
                refreshList={refreshHomeData}
                domains={domains}
                tags={tags}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                itemLabel="link"
            />
        </>
    );
}

export default Home;
