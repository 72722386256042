import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import logo from '../logo.png';
import { verifyEmail } from '../api';
import { useCommons } from '../CommonContext';

function EmailVerification({ email: propEmail, onResendEmail }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { setLoading } = useCommons();
    const [timer, setTimer] = useState(60);
    const [canResend, setCanResend] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState('pending');
    const [email, setEmail] = useState(propEmail);
    const verificationAttempted = useRef(false);

    const verifyEmailToken = async (email, token) => {
        setLoading(true);
        try {
            await verifyEmail(email, token);
            setVerificationStatus('success');
            setTimeout(() => {
                navigate('/');
            }, 3000);
        } catch (error) {
            console.error('Email verification failed:', error);
            setVerificationStatus('error');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const urlEmail = params.get('email');
        const token = params.get('token');

        if (urlEmail && token && !verificationAttempted.current) {
            const decodedEmail = decodeURIComponent(urlEmail);
            setEmail(decodedEmail);
            verifyEmailToken(decodedEmail, token);
            verificationAttempted.current = true;
        }
        return () => {
            verificationAttempted.current = false;
        };
    }, [location]);

    useEffect(() => {
        if (timer > 0) {
            const intervalId = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else {
            setCanResend(true);
        }
        return () => {
            setCanResend(true);
        };
    }, [timer]);

    const handleResendEmail = () => {
        setTimer(60);
        setCanResend(false);
        onResendEmail(email);
    };

    return (
        <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="welcome-logo mb-4">
                    <img src={logo} alt="Logo" />
                </div>
                <Card className="section-card mx-auto" style={{ maxWidth: '400px' }}>
                    <Card.Body>
                        <Card.Title as="h2" className="mb-4">Email Verification</Card.Title>
                        {verificationStatus === 'pending' && (
                            <>
                                <p>A verification email has been sent to {email}. Please check your inbox and click the verification link.</p>
                                <Button
                                    variant="primary"
                                    className="mb-3 w-100"
                                    onClick={handleResendEmail}
                                    disabled={!canResend}
                                >
                                    Resend Email {canResend ? '' : `(${timer}s)`}
                                </Button>
                                <Button variant="light" className="w-100" onClick={() => navigate('/')}>
                                    Back to Login
                                </Button>
                            </>
                        )}
                        {verificationStatus === 'success' && (
                            <>
                                <Alert variant="success">
                                    Email verified successfully. Redirecting to login page...
                                </Alert>
                                <Button variant="light" className="w-100" onClick={() => navigate('/')}>
                                    Back to Login
                                </Button>
                            </>
                        )}
                        {verificationStatus === 'error' && (
                            <>
                                <Alert variant="danger">
                                    Email verification failed. Please try again or request a new verification email.
                                </Alert>
                                <Button variant="primary" className="w-100" onClick={handleResendEmail}>
                                    Request New Verification Email
                                </Button>
                            </>
                        )}
                    </Card.Body>
                </Card>
                <div className="text-center mt-4">
                    <div className="footer-links mb-2">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/me?utm_source=tinyrebrand-app&utm_medium=email-verification" hrefLang="en" target="_blank">Marius</a></div>
                    </div>
                    <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                </div>
            </div>
        </div>
    );
}

export default EmailVerification;
