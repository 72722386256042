import React, { useState, useEffect, useRef } from 'react';
import { fetchLinkOfId, changeLink } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import QRCode from 'qrcode.react';

function EditLinkModal({ showModal, onCancel, onDelete, selectedId, refreshList, domains, tags }) {
    const { loading, setLoading } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [updatedAt, setUpdatedAt] = useState('');
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [status, setStatus] = useState(true);
    const [hasPassword, setHasPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [domainId, setDomainId] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [utmSource, setUtmSource] = useState('');
    const [utmMedium, setUtmMedium] = useState('');
    const [utmCampaign, setUtmCampaign] = useState('');
    const [utmContent, setUtmContent] = useState('');
    const [utmTerm, setUtmTerm] = useState('');
    const hasLoadedLinkData = useRef(false); // Ref to track if data has been loaded

    const loadLinkData = async (selectedId) => {
        setLoading(true);
        try {
            const response = await fetchLinkOfId(selectedId);
            const data = response.data ?? {};

            setUpdatedAt(data.updatedAt);
            setTitle(data.title);
            setSlug(data.slug);
            setUrl(data.url);
            setDescription(data.description);
            setStatus(data.status);
            setHasPassword(data.hasPassword);
            setDomainId(data.domainId);
            setSelectedTags(data.tags ? data.tags.map(tag => tag.id) : []);
            setUtmSource(data.utmSource || '');
            setUtmMedium(data.utmMedium || '');
            setUtmCampaign(data.utmCampaign || '');
            setUtmContent(data.utmContent || '');
            setUtmTerm(data.utmTerm || '');
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showModal && !hasLoadedLinkData.current) {
            loadLinkData(selectedId);
            hasLoadedLinkData.current = true; // Set the ref to true after loading data
        } else if (!showModal) {
            // Reset state when modal is closed
            setUpdatedAt('');
            setTitle('');
            setSlug('');
            setUrl('');
            setDescription('');
            setStatus(true);
            setHasPassword(false);
            setPassword('');
            setDomainId('');
            setSelectedTags([]);
            setUtmSource('');
            setUtmMedium('');
            setUtmCampaign('');
            setUtmContent('');
            setUtmTerm('');
            hasLoadedLinkData.current = false; // Reset the ref when modal is closed
        }
        return () => {
            hasLoadedLinkData.current = false;
        };
    }, [showModal]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await changeLink(selectedId, {
                updatedAt,
                title,
                url,
                description,
                status,
                hasPassword,
                password,
                tags: selectedTags,
                utmSource,
                utmMedium,
                utmCampaign,
                utmContent,
                utmTerm
            });
            showSuccessToast('Success', 'Link updated successfully');
            onCancel();
            refreshList();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleTagCheckboxChange = (tagId, isChecked) => {
        if (isChecked) {
            setSelectedTags([...selectedTags, tagId]);
        } else {
            setSelectedTags(selectedTags.filter((id) => id !== tagId));
        }
    };

    const selectedDomain = domains && domains.length > 0 ? domains.find(d => d.id === domainId) : null;

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="general" id="edit-link-tabs" variant="underline" className="mb-4">
                    <Tab eventKey="general" title="General">
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" name="title" value={title} onChange={handleTitleChange} required />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>URL</Form.Label>
                                <Form.Control type="text" name="url" value={url} onChange={(e) => setUrl(e.target.value)} required />
                            </Form.Group>
                            {selectedDomain && (
                                <div className="preview-box mb-3">
                                    <div>https://{selectedDomain.name}/{slug}</div>
                                </div>
                            )}
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" name="description" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Tags</Form.Label>
                                {tags && tags.length > 0 ? tags.map(tag => (
                                    <Form.Check
                                        key={tag.id}
                                        type="checkbox"
                                        label={tag.name}
                                        name="tags"
                                        checked={selectedTags.includes(tag.id)}
                                        onChange={(e) => handleTagCheckboxChange(tag.id, e.target.checked)}
                                    />
                                )) : null}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check type="switch" name="status" label="Status" checked={status} onChange={(e) => setStatus(e.target.checked)} />
                            </Form.Group>
                        </Form>
                    </Tab>
                    <Tab eventKey="security" title="Security">
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Check type="switch" name="hasPassword" label="Enable Password" checked={hasPassword} onChange={(e) => setHasPassword(e.target.checked)} />
                            </Form.Group>
                            {hasPassword === true && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            )}
                        </Form>
                    </Tab>
                    <Tab eventKey="tracking" title="Tracking">
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>UTM Source</Form.Label>
                                <Form.Control type="text" name="utmSource" value={utmSource} onChange={(e) => setUtmSource(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>UTM Medium</Form.Label>
                                <Form.Control type="text" name="utmMedium" value={utmMedium} onChange={(e) => setUtmMedium(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>UTM Campaign</Form.Label>
                                <Form.Control type="text" name="utmCampaign" value={utmCampaign} onChange={(e) => setUtmCampaign(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>UTM Content</Form.Label>
                                <Form.Control type="text" name="utmContent" value={utmContent} onChange={(e) => setUtmContent(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>UTM Term</Form.Label>
                                <Form.Control type="text" name="utmTerm" value={utmTerm} onChange={(e) => setUtmTerm(e.target.value)} />
                            </Form.Group>
                        </Form>
                    </Tab>
                    {selectedDomain && (
                        <Tab eventKey="qr" title="QR Code">
                            <div className="text-center">
                                <QRCode value={`https://${selectedDomain.name}/${slug}`} size={256} />
                            </div>
                            <p className="mt-3 text-center">https://{selectedDomain.name}/{slug}</p>
                        </Tab>
                    )}
                    <Tab eventKey="help" title="Help">
                        <div>
                            <h3>How to use the UTM parameters</h3>
                            <p>UTM parameters are used to track the source of traffic to your website. They are added to the URL of the link you are creating.</p>
                            <p>If UTM parameters are set when creating a link, they will be used as default values for the UTM parameters for statistics and added to target URL when redirecting.</p>
                            <p>You can also pass UTM parameters to the rebranded URL and they will replace the default UTM parameters.</p>
                            <div className="preview-box" style={{ overflowX: 'scroll', whiteSpace: 'nowrap', maxWidth: '100%', overflowY: 'hidden' }}>
                                <p>https://your-domain.com?utm_source=<span style={{ backgroundColor: 'yellow' }}>google</span>&utm_medium=<span style={{ backgroundColor: 'yellow' }}>cpc</span>&utm_campaign=<span style={{ backgroundColor: 'yellow' }}>campaign_name</span>&utm_content=<span style={{ backgroundColor: 'yellow' }}>content_name</span>&utm_term=<span style={{ backgroundColor: 'yellow' }}>term_name</span></p>
                            </div>
                            <p>&nbsp;</p>
                            <h3>How to use custom variables</h3>
                            <p>You can use custom variables like [var] in your target URL to get values from rebranded URLs query parameters</p>
                            <p>This can be useful for when you want to use dynamic values in your target URL</p>
                            <p><strong>Examples:</strong></p>
                            <p>You want a target url to get a dynamic blog post ID from your rebranded URL</p>
                            <p>You create a target link like this:</p>
                            <div className="preview-box mb-3" style={{ whiteSpace: 'nowrap', maxWidth: '100%' }}>
                                <p>https://your-blog.com/?post=<span style={{ backgroundColor: 'yellow' }}>[post_id]</span></p>
                            </div>
                            <p>You use a rebranded link like this:</p>
                            <div className="preview-box mb-3" style={{ whiteSpace: 'nowrap', maxWidth: '100%' }}>
                                <p>https://your-domain.com/?post=<span style={{ backgroundColor: 'yellow' }}>123</span></p>
                            </div>
                            <p>And redirected to:</p>
                            <div className="preview-box mb-3" style={{ whiteSpace: 'nowrap', maxWidth: '100%' }}>
                                https://your-blog.com/?post=<span style={{ backgroundColor: 'yellow' }}>123</span>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onDelete(selectedId)} className="me-auto">Delete</Button>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Link'}
                </Button>
            </Modal.Footer>
        </Modal >
    );
}

export default EditLinkModal;
