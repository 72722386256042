import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { signInWithGoogle } from '../api';
import { useCommons } from '../CommonContext';

const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

const LoginWithGoogleButton = (props) => {
    const { setUser, sendEvent, setLoading } = useCommons();

    const handleLoginSuccess = async (response) => {
        console.log(response);
        setLoading(true);
        try {
            const userResponse = await signInWithGoogle(response.credential);
            const token = userResponse.data.token;
            const user = userResponse.data.user;

            setUser(user);
            sendEvent('login', 'logged_in_google', '');
            localStorage.setItem("jwtToken", token);

            props.onLoginSuccess();
        }
        catch (error) {
            props.setErrorMessage(error.message || 'An error occurred while logging in with Google');
        } finally {
            setLoading(false);
        }
    };

    const handleLoginFailure = (error) => {
        console.error(error);
    };

    return (
        <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
            />
        </GoogleOAuthProvider>
    );
};

export default LoginWithGoogleButton;
