import React, { useState, useEffect } from 'react';
import { addDomain } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';

function AddDomainModal({ showModal, onCancel, refreshList }) {
    const { loading, setLoading, refreshLimits, sendEvent } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [name, setName] = useState('');

    useEffect(() => {
        if (showModal) {
            setName('');
        }
    }, [showModal]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await addDomain({ name });
            showSuccessToast('Success', 'Domain added successfully');
            sendEvent('user_domain', 'added', '');
            onCancel();
            refreshList();
            refreshLimits();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Add Domain</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Domain Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Adding...' : 'Add Domain'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddDomainModal;
