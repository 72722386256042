import React, { useEffect, useState, useRef } from 'react';
import { Route, Link, NavLink, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Home from './Home';
import Account from './Account';
import Links from './Links';
import Tags from './Tags';
import Domains from './Domains';
import Subscription from './Subscription';
import Stats from './Stats';
import { useCommons } from '../CommonContext';
import { fetchUser } from '../api';
import AddLinkModal from './AddLinkModal';
import { fetchAvailableDomains, fetchTags } from '../api';
import Button from 'react-bootstrap/Button';
import { FaBars } from 'react-icons/fa';
import { useToast } from '../ToastContext';

function Dashboard({ onLogout }) {
    const navigate = useNavigate();
    const { user, setUser, favoriteTags, setFavoriteTags, setLimits, refreshLimits, refreshFavoriteTags, setLoading } = useCommons();
    const { showErrorToast } = useToast();
    const [showAddLinkModal, setShowAddLinkModal] = useState(false);
    const [domains, setDomains] = useState([]);
    const [tags, setTags] = useState([]);
    const hasLoadedDashboardData = useRef(false);

    const loadUserData = async () => {
        setLoading(true);
        try {
            const response = await fetchUser();
            setUser(response.data);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const loadDomainsAndTags = async () => {
        setLoading(true);
        try {
            const domainsResponse = await fetchAvailableDomains();
            setDomains(domainsResponse.data ?? []);
            const tagsResponse = await fetchTags();
            setTags(tagsResponse.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const refreshDashboardData = () => {
        refreshLimits();
        refreshFavoriteTags();
    }

    useEffect(() => {
        if (!hasLoadedDashboardData.current) {
            loadUserData();
            loadDomainsAndTags();
            refreshLimits();
            refreshFavoriteTags();
            hasLoadedDashboardData.current = true;
        }
        return () => {
            hasLoadedDashboardData.current = false;
        };
    }, []);

    const handleLogout = () => {
        if (typeof onLogout === 'function') {
            setLimits(null);
            setFavoriteTags([]);
            onLogout();
            navigate("/");
        }
    };

    function toggleSidebar() {
        const sidebar = document.querySelector('.sidebar-container');
        sidebar.classList.toggle('open');
    }

    const handleSidebarLinkClick = () => {
        const sidebar = document.querySelector('.sidebar-container');
        sidebar.classList.remove('open');
    };

    return (
        <>
            <div className="sidebar-toggle-header sidebar-toggle-header-mobile">
                <Button variant="primary" className="btn" onClick={toggleSidebar}>
                    <FaBars />
                </Button>
                <Link to="/" className="sidebar-logo">
                    <img src="/logo-transparent.png" alt="Tiny Rebrand" />
                </Link>
            </div>
            <div className="main-container">
                <div className="sidebar-container">
                    <div className="sidebar">
                        <div className="sidebar-toggle-header">
                            <Button variant="primary" className="btn" onClick={toggleSidebar}>
                                <FaBars />
                            </Button>
                            <Link to="/" className="sidebar-logo">
                                <img src="/logo-transparent.png" alt="Tiny Rebrand" />
                            </Link>
                        </div>
                        <div className="sidebar-menu">
                            <div className="sidebar-action mb-3">
                                <Button variant="primary" className="btn w-100" onClick={() => setShowAddLinkModal(true)}>
                                    <i className="bi bi-plus-lg me-1"></i>Add Link
                                </Button>
                            </div>
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/links" onClick={handleSidebarLinkClick}>Links</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/tags" onClick={handleSidebarLinkClick}>Tags</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/domains" onClick={handleSidebarLinkClick}>Domains</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/stats" onClick={handleSidebarLinkClick}>Stats</NavLink>
                                </li>
                            </ul>

                            {favoriteTags && favoriteTags.length > 0 && (
                                <div className="sidebar-favorites">
                                    <h6>Favorite Tags</h6>
                                    <ul className="nav nav-pills flex-column">
                                        {favoriteTags.map((tag) => (
                                            <li key={tag.id} className="nav-item">
                                                <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to={`/links/tag/${tag.id}`} onClick={handleSidebarLinkClick}>{tag.name}</NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="sidebar-account">
                            <ul className="nav nav-pills flex-column mb-auto">
                                <li className="nav-item">
                                    <NavLink className={({ isActive }) => "nav-link" + (isActive ? " active" : "")} to="/subscription" onClick={handleSidebarLinkClick}>Subscription</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {user && user.email}
                                    </a>
                                    <ul className="dropdown-menu shadow-sm border-0">
                                        <li><NavLink className={({ isActive }) => "dropdown-item" + (isActive ? " active" : "")} to="/account">Account</NavLink></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={handleLogout} href="#">Sign out</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <main className="main-content">
                    <div className="mb-auto">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/links" element={<Links />} />
                            <Route path="/links/tag/:tagId" element={<Links />} />
                            <Route path="/tags" element={<Tags />} />
                            <Route path="/domains" element={<Domains />} />
                            <Route path="/subscription" element={<Subscription />} />
                            <Route path="/account" element={<Account onAccountDelete={onLogout} />} />
                            <Route path="/stats" element={<Stats />} />
                        </Routes>
                    </div>
                    <div className="page-footer text-center">
                        <div className="footer-links">
                            <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                            <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        </div>
                        <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                    </div>
                </main>
            </div>
            <AddLinkModal
                showModal={showAddLinkModal}
                onCancel={() => setShowAddLinkModal(false)}
                refreshList={refreshDashboardData}
                domains={domains}
                tags={tags}
            />
        </>
    );
}

Dashboard.propTypes = {
    onLogout: PropTypes.func.isRequired
};

export default Dashboard;
