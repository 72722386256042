import React, { useState } from 'react';
import { deleteUser } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import { useNavigate } from 'react-router-dom';

function DeleteAccountModal({ showModal, onCancel, onDeleteAccount }) {
    const { loading, setLoading } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await deleteUser({ password });
            showSuccessToast('Success', 'Account deleted successfully');
            onDeleteAccount();
            navigate('/account-deleted');
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Delete Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Are you sure you want to delete your account?
                </p>
                <p>
                    This action is irreversible. All your account information will be removed, and you won't be able to recover it. If you have an active subscription, it will be cancelled. The current paid period will end, but no refunds will be provided.
                </p>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="danger" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Deleting...' : 'Delete Account'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteAccountModal;
