import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { resetPassword } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function ResetPassword() {
    const navigate = useNavigate();
    const { sendEvent, setLoading } = useCommons();
    const [searchParams] = useSearchParams();
    const userId = searchParams.get('userId');
    const tokenId = searchParams.get('tokenId');
    const token = searchParams.get('token');
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleResetPassword = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (newPassword !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }
        try {
            sendEvent('reset_password', 'changed', '');
            await resetPassword({ userId, tokenId, token, newPassword });
            setSuccessMessage("Password has been reset successfully. You can now login with your new password.");
            setTimeout(() => navigate('/'), 5000);
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while resetting the password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="welcome-logo mb-4">
                    <img src={logo} alt="Logo" />
                </div>
                <Card className="section-card mx-auto" style={{ maxWidth: '400px' }}>
                    <Card.Body>
                        <Card.Title as="h2" className="mb-4">Reset Password</Card.Title>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                        <form onSubmit={handleResetPassword} className="mb-3">
                            <div className="form-group mb-3">
                                <label>New Password</label>
                                <input type="password" className="form-control" onChange={e => setNewPassword(e.target.value)} />
                            </div>
                            <div className="form-group mb-3">
                                <label>Confirm Password</label>
                                <input type="password" className="form-control" onChange={e => setConfirmPassword(e.target.value)} />
                            </div>
                            <Button type="submit" variant="primary" className="w-100">Reset Password</Button>
                        </form>
                    </Card.Body>
                </Card>
                <div className="text-center mt-4">
                    <div className="footer-links mb-2">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/me?utm_source=tinyrebrand-app&utm_medium=reset-password" hrefLang="en" target="_blank">Marius</a></div>
                    </div>
                    <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
