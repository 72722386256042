// src/components/TwoFactorAuthModal.js
import React, { useState, useEffect, useRef } from 'react';
import { requestEnableTwoFactor, enableTwoFactor, disableTwoFactor } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import QRCode from 'qrcode.react';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';

function TwoFactorAuthModal({ showModal, onCancel, user, refreshUserData }) {
    const { loading, setLoading } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [secret, setSecret] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [token, setToken] = useState('');
    const hasRequestedSecret = useRef(false);

    const requestSecret = async () => {
        setLoading(true);
        try {
            const response = await requestEnableTwoFactor();
            setSecret(response.data.secret);
            setQrCodeUrl(response.data.qrurl);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showModal && !user.twoFactorEnabled && !hasRequestedSecret.current) {
            requestSecret();
            hasRequestedSecret.current = true;
        }
        return () => {
            hasRequestedSecret.current = false;
        };
    }, [showModal]);

    const handleEnable = async () => {
        setLoading(true);
        try {
            await enableTwoFactor({ token });
            showSuccessToast('Success', 'Two-Factor Authentication enabled successfully');
            onCancel();
            refreshUserData();
            setToken('');
            setSecret('');
            setQrCodeUrl('');
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDisable = async () => {
        setLoading(true);
        try {
            await disableTwoFactor({ token });
            showSuccessToast('Success', 'Two-Factor Authentication disabled successfully');
            onCancel();
            refreshUserData();
            setToken('');
            setSecret('');
            setQrCodeUrl('');
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{user.twoFactorEnabled ? 'Disable Two-Factor Authentication' : 'Enable Two-Factor Authentication'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {user.twoFactorEnabled ? (
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Enter 2FA Token to Disable</Form.Label>
                            <Form.Control
                                type="text"
                                name="token"
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                ) : (
                    <>
                        <div className="text-center">
                            <QRCode value={qrCodeUrl} size={256} />
                        </div>
                        <p className="mt-3 text-center">Secret: {secret}</p>
                        <Form>
                            <Form.Group className='mb-3'>
                                <Form.Label>Enter 6 Digit Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="token"
                                    value={token}
                                    onChange={(e) => setToken(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={user.twoFactorEnabled ? handleDisable : handleEnable} disabled={loading}>
                    {loading ? (user.twoFactorEnabled ? 'Disabling...' : 'Enabling...') : (user.twoFactorEnabled ? 'Disable' : 'Enable')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default TwoFactorAuthModal;
