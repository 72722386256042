import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { signUp } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function Register(props) {
    const navigate = useNavigate();
    const { sendEvent, setLoading } = useCommons();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const returnUrl = process.env.REACT_APP_URL;

    const handleRegister = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (password !== repeatPassword) {
            setErrorMessage('Passwords do not match');
            setLoading(false);
            return;
        }

        if (!acceptedTerms) {
            setErrorMessage('You must accept the Terms of Service and Privacy Policy');
            setLoading(false);
            return;
        }

        try {
            const response = await signUp(email, password, returnUrl + '/verify-email');
            const user = response.data.user;
            sendEvent('register', 'registered', '');
            props.onEmailVerification(email);
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred during registration');
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="welcome-logo mb-4">
                    <img src={logo} alt="Logo" />
                </div>
                <Card className="section-card mx-auto" style={{ maxWidth: '400px' }}>
                    <Card.Body>
                        <Card.Title as="h2" className="mb-4">Register</Card.Title>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        <Form onSubmit={handleRegister} className="mb-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" onChange={e => setPassword(e.target.value)} minLength="6" />
                                <Form.Text>Password must be 6 or more characters</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Repeat Password</Form.Label>
                                <Form.Control type="password" onChange={e => setRepeatPassword(e.target.value)} minLength="6" />
                                <Form.Text>Password must be 6 or more characters</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id="termsCheckbox"
                                    checked={acceptedTerms}
                                    onChange={e => setAcceptedTerms(e.target.checked)}
                                    label={<>I accept the <a href="https://www.tinyrebrand.com/terms-of-service" target="_blank">Terms of Service</a> and <a href="https://www.tinyrebrand.com/privacy-policy" target="_blank">Privacy Policy</a></>}
                                />
                            </Form.Group>
                            <Button type="submit" variant="primary" className="w-100 mb-3">Register</Button>
                            <Button variant="light" className="w-100" onClick={() => navigate('/')}>
                                Already have an account? Login
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
                <div className="text-center mt-4">
                    <div className="footer-links mb-2">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/me?utm_source=tinyrebrand-app&utm_medium=register" hrefLang="en" target="_blank">Marius</a></div>
                    </div>
                    <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                </div>
            </div>
        </div>
    );
}

export default Register;
