import React, { useState, useEffect, useRef } from 'react';
import { fetchStats } from '../api';
import Button from 'react-bootstrap/Button';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

function Stats() {
    const { setLoading } = useCommons();
    const { showErrorToast } = useToast();
    const [stats, setStats] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const hasLoadedStatsData = useRef(false);

    // Helper function to get the start and end of the current week
    const getCurrentWeekDates = () => {
        const now = new Date();
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
        const endOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 6));
        return {
            start: startOfWeek.toISOString().split('T')[0],
            end: endOfWeek.toISOString().split('T')[0]
        };
    };

    const { start, end } = getCurrentWeekDates();
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await fetchStats({ startDate, endDate, q: searchQuery });
            setStats(response.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!hasLoadedStatsData.current) {
            handleSearch();
            hasLoadedStatsData.current = true;
        }
        return () => {
            hasLoadedStatsData.current = false;
        };
    }, []);

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Escape') {
            setSearchQuery('');
        }
    };

    return (
        <>
            <div className="page stats">
                <div className="section-title">Stats</div>
                <div className="card section-card">
                    <div className="card-body">
                        <Form>
                            <div className="row mb-3">
                                <div className="col">
                                    <Form.Control
                                        type="date"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onChange={e => setStartDate(e.target.value)}
                                    />
                                </div>
                                <div className="col">
                                    <Form.Control
                                        type="date"
                                        placeholder="End Date"
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div className="col">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        onChange={e => setSearchQuery(e.target.value)}
                                    />
                                </div>
                                <div className="col">
                                    <Button variant="primary" onClick={handleSearch}>Search</Button>
                                </div>
                            </div>
                        </Form>

                        {stats.length > 0 ? (
                            <div className="table-responsive mb-3">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Domain / Link</th>
                                            <th>Visits</th>
                                            <th>Password Passed</th>
                                            <th>Password Failed</th>
                                            <th>UTM Source</th>
                                            <th>UTM Medium</th>
                                            <th>UTM Campaign</th>
                                            <th>UTM Term</th>
                                            <th>UTM Content</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stats.map((stat, index) => (
                                            <tr key={index}>
                                                <td>{stat.date}</td>
                                                <td>{stat.domainName} / {stat.linkName}</td>
                                                <td className="text-end">{stat.visits}</td>
                                                <td className="text-end">{stat.passwordPassed}</td>
                                                <td className="text-end">{stat.passwordFailed}</td>
                                                <td>{stat.utmSource}</td>
                                                <td>{stat.utmMedium}</td>
                                                <td>{stat.utmCampaign}</td>
                                                <td>{stat.utmTerm}</td>
                                                <td>{stat.utmContent}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="2"><strong>Totals</strong></td>
                                            <td className="text-end"><strong>{stats.reduce((sum, stat) => sum + stat.visits, 0)}</strong></td>
                                            <td className="text-end"><strong>{stats.reduce((sum, stat) => sum + stat.passwordPassed, 0)}</strong></td>
                                            <td className="text-end"><strong>{stats.reduce((sum, stat) => sum + stat.passwordFailed, 0)}</strong></td>
                                            <td colSpan="5"></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        ) : <p>No stats available.</p>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stats;
