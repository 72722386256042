import React, { createContext, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { fetchUserLimits, fetchFavoriteTags } from './api';

const CommonContext = createContext();

const sendEvent = (category, action, label = '', value = 0) => {
    ReactGA.event({
        category,
        action,
        label,
        value,
        nonInteraction: true,
        transport: 'xhr'
    });
};

export const CommonsProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [limits, setLimits] = useState(null);
    const [favoriteTags, setFavoriteTags] = useState([]);

    const clearLoading = () => {
        setLoading(false);
    };

    const refreshLimits = async () => {
        try {
            const response = await fetchUserLimits();
            setLimits(response.data);
        } catch (error) {
            console.error('Error fetching user limits:', error);
        }
    }

    const refreshFavoriteTags = async () => {
        try {
            const response = await fetchFavoriteTags();
            setFavoriteTags(response.data);
        } catch (error) {
            console.error('Error fetching favorite tags:', error);
        }
    }

    return (
        <CommonContext.Provider value={{
            loading,
            setLoading,
            user,
            setUser,
            limits,
            setLimits,
            sendEvent,
            refreshLimits,
            favoriteTags,
            setFavoriteTags,
            refreshFavoriteTags
        }}>
            {loading &&
                <div className="loading-indicator">
                    <button type="button" className="btn-close" onClick={clearLoading} aria-label="Close"></button>
                    <div>Loading...</div>
                </div>
            }

            {children}
        </CommonContext.Provider>
    );
};

export const useCommons = () => {
    const context = useContext(CommonContext);
    if (!context) {
        throw new Error('useCommons must be used within a CommonsProvider');
    }
    return context;
};
