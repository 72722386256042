import React, { createContext, useContext, useState } from 'react';
import Toast from 'react-bootstrap/Toast';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState('');
    const [variant, setVariant] = useState('default'); // default, error, success

    const toastStyles = {
        default: {},
        error: { backgroundColor: '#f8d7da', borderColor: '#f5c2c7' },
        success: { backgroundColor: '#d1e7dd', borderColor: '#badbcc' }
    };

    const showToast = (title, message, variant = 'default') => {
        setTitle(title);
        setMessage(message);
        setVariant(variant);
        setShow(true);
    };
    const showErrorToast = (title, message) => showToast(title, message, 'error');
    const showSuccessToast = (title, message) => showToast(title, message, 'success');
    const hideToast = () => setShow(false);

    return (
        <ToastContext.Provider value={{
            showToast,
            showErrorToast,
            showSuccessToast
        }}>
            {children}
            <div className="toast-container position-fixed bottom-0 start-0 p-3">
                <Toast onClose={hideToast} show={show} delay={5000} autohide style={toastStyles[variant]}>
                    <Toast.Header>
                        <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </div>
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};
