import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { requestPasswordReset } from '../api';
import logo from '../logo.png';
import { useCommons } from '../CommonContext';

function ResetPasswordRequest() {
    const navigate = useNavigate();
    const { sendEvent, setLoading } = useCommons();
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleRequestReset = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            sendEvent('reset_password', 'requested', '');
            await requestPasswordReset({ email });
            setSuccessMessage("A password reset link has been sent to your email.");
        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while requesting a password reset');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="welcome-logo mb-4">
                    <img src={logo} alt="Logo" />
                </div>
                <Card className="section-card mx-auto" style={{ maxWidth: '400px' }}>
                    <Card.Body>
                        <Card.Title as="h2" className="mb-4">Reset Password</Card.Title>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                        <form onSubmit={handleRequestReset} className="mb-3">
                            <div className="form-group mb-3">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <Button type="submit" variant="primary" className="w-100 mb-3">Send Reset Link</Button>
                            <Button variant="light" className="w-100" onClick={() => navigate('/')}>Back to Login</Button>
                        </form>
                    </Card.Body>
                </Card>
                <div className="text-center mt-4">
                    <div className="footer-links mb-2">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/me?utm_source=tinyrebrand-app&utm_medium=reset-password-request" hrefLang="en" target="_blank">Marius</a></div>
                    </div>
                    <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordRequest;
