import React, { useState, useEffect, useRef } from 'react';
import { fetchUser } from '../api';
import ChangeEmailModal from './ChangeEmailModal';
import ChangePasswordModal from './ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal'; // Import the new modal
import TwoFactorAuthModal from './TwoFactorAuthModal';
import Button from 'react-bootstrap/Button';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';

function Account({ onDeleteAccount }) {
    const { setLoading } = useCommons();
    const { showErrorToast } = useToast();
    const [user, setUser] = useState({});
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false); // Add state for the delete account modal
    const [showTwoFactorAuthModal, setShowTwoFactorAuthModal] = useState(false);
    const hasLoadedUserData = useRef(false);

    const loadUserData = async () => {
        setLoading(true);
        try {
            const response = await fetchUser();
            setUser(response.data);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!hasLoadedUserData.current) {
            loadUserData();
            hasLoadedUserData.current = true;
        }
        return () => {
            hasLoadedUserData.current = false;
        };
    }, []);

    return (
        <>
            <div className="page page-narrow account">
                <div className="section-title">Profile</div>
                <div className="card section-card">
                    <div className="card-body">
                        <div className="card-title">Email</div>
                        <div className="mb-3">{user.email}</div>
                        <Button variant="primary" onClick={() => setShowEmailModal(true)}>Change Email</Button>
                    </div>
                </div>
                <div className="section-title">Security</div>
                <div className="card section-card">
                    <div className="card-body">
                        <div className="card-title">Password</div>
                        <div className="mb-3">********</div>
                        <Button variant="primary" onClick={() => setShowPasswordModal(true)}>Change Password</Button>
                    </div>
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <div className="card-title">Two-Factor Authentication (2FA)</div>
                        <div className="mb-3">
                            {user.twoFactorEnabled ? 'Enabled' : 'Disabled'}
                        </div>
                        <Button variant="primary" onClick={() => setShowTwoFactorAuthModal(true)}>
                            {user.twoFactorEnabled ? 'Disable 2FA' : 'Enable 2FA'}
                        </Button>
                    </div>
                </div>
                <div className="section-title">Delete Account</div>
                <div className="card section-card">
                    <div className="card-body">
                        <div className="mb-3">Delete your account and all information</div>
                        <Button variant="danger" onClick={() => setShowDeleteAccountModal(true)}>Delete Account</Button>
                    </div>
                </div>
            </div>
            <ChangeEmailModal
                showModal={showEmailModal}
                onCancel={() => setShowEmailModal(false)}
                data={user}
                refreshUserData={loadUserData}
            />
            <ChangePasswordModal
                showModal={showPasswordModal}
                onCancel={() => setShowPasswordModal(false)}
                data={user}
            />
            <DeleteAccountModal
                showModal={showDeleteAccountModal}
                onCancel={() => setShowDeleteAccountModal(false)}
                onDeleteAccount={onDeleteAccount}
            />
            <TwoFactorAuthModal
                showModal={showTwoFactorAuthModal}
                onCancel={() => setShowTwoFactorAuthModal(false)}
                user={user}
                refreshUserData={loadUserData}
            />
        </>
    );
}

export default Account;
