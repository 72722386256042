import React, { useState } from 'react';
import { changeUserPassword } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';

function ChangePasswordModal({ showModal, onCancel, data }) {
    const { loading, setLoading } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const handleSubmit = async () => {
        if (newPassword !== repeatPassword) {
            showErrorToast('Error', 'Passwords do not match');
            return;
        }

        setLoading(true);
        try {
            await changeUserPassword({ updatedAt: data.updatedAt, currentPassword, newPassword });
            showSuccessToast('Success', 'Password updated successfully');
            onCancel();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                        <p className="form-text">Password must be 8 or more characters</p>
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label>Repeat New Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            required
                        />
                        <p className="form-text">Password must be 8 or more characters</p>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Password'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChangePasswordModal;
