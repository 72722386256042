import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useCommons } from './CommonContext'; // Import the useCommons hook

const CookieConsentBanner = () => {
    const { sendEvent } = useCommons(); // Destructure sendEvent from useCommons
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        if (!getCookie('cookieConsent')) {
            setShowBanner(true);
        }

        if (getCookie('cookieConsent') === 'accepted') {
            initializeGA();
        }
    }, []);

    const setCookie = (name, value, days) => {
        const d = new Date();
        d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    const getCookie = (name) => {
        const cname = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cname) === 0) {
                return c.substring(cname.length, c.length);
            }
        }
        return "";
    };

    const initializeGA = () => {
        const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS;
        ReactGA.initialize(googleAnalyticsId);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        sendEvent('CookieConsent', 'Accepted'); // Track the event
    };

    const handleAccept = () => {
        setCookie('cookieConsent', 'accepted', 365);
        initializeGA();
        setShowBanner(false);
    };

    const handleDecline = () => {
        setCookie('cookieConsent', 'declined', 365);
        setShowBanner(false);
        sendEvent('CookieConsent', 'Declined'); // Track the event
    };

    if (!showBanner) {
        return null;
    }

    return (
        <div className="cookie-consent-banner">
            <p>We use cookies to ensure that we give you the best experience on our website. By continuing to use this site, you consent to the use of cookies.</p>
            <button className="btn btn-primary me-2" onClick={handleAccept}>Accept</button>
            <button className="btn btn-secondary" onClick={handleDecline}>Decline</button>
        </div>
    );
};

export default CookieConsentBanner;
