import React, { useState, useEffect, useRef } from 'react';
import { fetchTags, deleteTag } from '../api';
import Button from 'react-bootstrap/Button';
import AddTagModal from './AddTagModal';
import EditTagModal from './EditTagModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import Form from 'react-bootstrap/Form';

function Tags() {
    const { setLoading, refreshLimits, limits, refreshFavoriteTags } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [tags, setTags] = useState([]);
    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [showEditTagModal, setShowEditTagModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTagId, setSelectedTagId] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const hasLoadedTagsData = useRef(false);

    const loadTagsData = async () => {
        setLoading(true);
        try {
            const response = await fetchTags();
            setTags(response.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!hasLoadedTagsData.current) {
            loadTagsData();
            hasLoadedTagsData.current = true;
        }
        return () => {
            hasLoadedTagsData.current = false;
        };
    }, []);

    const handleDeleteClick = (id) => {
        setSelectedTagId(id);
        setShowDeleteModal(true);
        setShowEditTagModal(false);
    };

    const handleDeleteConfirm = async () => {
        setLoading(true);
        try {
            await deleteTag(selectedTagId);
            showSuccessToast('Success', 'Tag deleted successfully');
            loadTagsData();
            refreshLimits();
            refreshFavoriteTags();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setShowDeleteModal(false);
            setShowEditTagModal(false);
            setSelectedTagId(null);
            setLoading(false);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setShowEditTagModal(true);
    };

    const handleEditClick = (tag) => {
        setSelectedTagId(tag.id);
        setShowEditTagModal(true);
    };

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Escape') {
            setSearchQuery('');
        }
    };

    const filteredTags = tags.filter(tag =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="page page-narrow tags">
                <div className="section-title">Tags</div>
                <div className="card section-card">
                    <div className="card-body">
                        <Button variant="primary" onClick={() => setShowAddTagModal(true)} className="mb-3">Add Tag</Button>
                        <Form.Control
                            type="text"
                            placeholder="Search tags..."
                            className="mb-3"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleSearchKeyPress}
                        />
                        {filteredTags.length > 0 ? (
                            <>
                                <div className="data-list mb-3">
                                    {filteredTags.map(tag => (
                                        <div key={tag.id} className="data-list-item clickable" onClick={() => handleEditClick(tag)}>
                                            <span>{tag.name}</span>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : <p>No tags available.</p>}
                        <div className="data-list-limits">Tags: {limits ? limits.current_tags : null} / {limits ? limits.max_tags : null}</div>
                    </div>
                </div>
            </div>
            <AddTagModal
                showModal={showAddTagModal}
                onCancel={() => setShowAddTagModal(false)}
                refreshList={loadTagsData}
            />
            <EditTagModal
                showModal={showEditTagModal}
                onCancel={() => setShowEditTagModal(false)}
                onDelete={handleDeleteClick}
                selectedId={selectedTagId}
                refreshList={loadTagsData}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                itemLabel="tag"
            />
        </>
    );
}

export default Tags;
