import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import logo from '../logo.png';

function AccountDeleted() {
    const navigate = useNavigate();

    return (
        <div className="container-fluid welcome-container d-flex flex-column justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="welcome-logo mb-4">
                    <img src={logo} alt="Logo" />
                </div>
                <Card className="section-card mx-auto" style={{ maxWidth: '400px' }}>
                    <Card.Body>
                        <Card.Title as="h2" className="mb-4">Account Deleted</Card.Title>
                        <p>Your account has been successfully deleted. I'm sorry to see you go.</p>
                        <p>I would appreciate it if you could take a moment to let me know why you decided to delete your account. Your feedback would help me build a better project.</p>
                        <p>Email: <a href="mailto:hello@tinyrebrand.com">hello@tinyrebrand.com</a></p>
                        <p>X (Twitter): <a href="https://vclck.com/my-twitter?utm_source=tinyrebrand-app&utm_medium=account-deleted" hrefLang="en" target="_blank">@xkrasauskas</a></p>
                        <Button variant="light" className="w-100" onClick={() => navigate('/')}>
                            Back to Login
                        </Button>
                    </Card.Body>
                </Card>
                <div className="text-center mt-4">
                    <div className="footer-links mb-2">
                        <div><a href="https://www.tinyrebrand.com/terms-of-service" target="_blank" title="Tiny Rebrand Terms of Service">Terms of Service</a></div>
                        <div><a href="https://www.tinyrebrand.com/privacy-policy" target="_blank" title="Tiny Rebrand Privacy Policy">Privacy Policy</a></div>
                        <div>by <a href="https://vclck.com/me?utm_source=tinyrebrand-app&utm_medium=account-deleted" hrefLang="en" target="_blank">Marius</a></div>
                    </div>
                    <p>© 2024 All rights reserved. From 🇪🇺 with ♥️.</p>
                </div>
            </div>
        </div>
    );
}

export default AccountDeleted;
