import React, { useState, useEffect, useRef } from 'react';
import { fetchTagOfId, changeTag } from '../api';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';

function EditTagModal({ showModal, onCancel, onDelete, selectedId, refreshList }) {
    const { loading, setLoading, refreshFavoriteTags } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [updatedAt, setUpdatedAt] = useState('');
    const [name, setName] = useState('');
    const [isFavorite, setIsFavorite] = useState(false);
    const hasLoadedTagData = useRef(false); // Ref to track if data has been loaded

    const loadTagData = async (selectedId) => {
        setLoading(true);
        try {
            const response = await fetchTagOfId(selectedId);
            const data = response.data ?? {};

            setUpdatedAt(data.updatedAt);
            setName(data.name);
            setIsFavorite(data.isFavorite);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showModal && !hasLoadedTagData.current) { // Check if modal is shown and data hasn't been loaded
            loadTagData(selectedId);
            hasLoadedTagData.current = true; // Set the ref to true after loading data
        } else if (!showModal) {
            // Reset state when modal is closed
            setUpdatedAt('');
            setName('');
            setIsFavorite(false);
            hasLoadedTagData.current = false; // Reset the ref when modal is closed
        }
        return () => {
            hasLoadedTagData.current = false;
        };
    }, [showModal]);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await changeTag(selectedId, {
                updatedAt,
                name,
                isFavorite
            });
            showSuccessToast('Success', 'Tag updated successfully');
            onCancel();
            refreshList();
            refreshFavoriteTags();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Edit Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className='mb-3'>
                        <Form.Label>Tag Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Check
                            type="checkbox"
                            label="Favorite"
                            checked={isFavorite}
                            onChange={(e) => setIsFavorite(e.target.checked ? 1 : 0)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => onDelete(selectedId)} className="me-auto">Delete</Button>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                    {loading ? 'Updating...' : 'Update Tag'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditTagModal;
