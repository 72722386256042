import React, { useState, useEffect, useRef } from 'react';
import { fetchDomains, deleteDomain } from '../api';
import Button from 'react-bootstrap/Button';
import AddDomainModal from './AddDomainModal';
import VerifyDomainModal from './VerifyDomainModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useToast } from '../ToastContext';
import { useCommons } from '../CommonContext';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import bluehostLogo from './../partners/bluehost.png';
import cloudflareLogo from './../partners/cloudflare.png';
import hostingerLogo from './../partners/hostinger.png';
import namecheapLogo from './../partners/namecheap.png';

function Domains() {
    const { setLoading, refreshLimits, limits } = useCommons();
    const { showErrorToast, showSuccessToast } = useToast();
    const [domains, setDomains] = useState([]);
    const [showAddDomainModal, setShowAddDomainModal] = useState(false);
    const [showVerifyDomainModal, setShowVerifyDomainModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDomainId, setSelectedDomainId] = useState(null);
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const hasLoadedDomainsData = useRef(false);

    const loadDomainsData = async () => {
        setLoading(true);
        try {
            const response = await fetchDomains();
            setDomains(response.data ?? []);
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!hasLoadedDomainsData.current) {
            loadDomainsData();
            hasLoadedDomainsData.current = true;
        }
        return () => {
            hasLoadedDomainsData.current = false;
        };
    }, []);

    const handleVerifyClick = (domain) => {
        setSelectedDomain(domain);
        setSelectedDomainId(domain.id);
        setShowVerifyDomainModal(true);
    };

    const handleDeleteClick = (id) => {
        setSelectedDomainId(id);
        setShowDeleteModal(true);
        setShowVerifyDomainModal(false);
    };

    const handleDeleteConfirm = async () => {
        setLoading(true);
        try {
            await deleteDomain(selectedDomainId);
            showSuccessToast('Success', 'Domain deleted successfully');
            loadDomainsData();
            refreshLimits();
        } catch (error) {
            showErrorToast('Error', error.message);
        } finally {
            setShowDeleteModal(false);
            setShowVerifyDomainModal(false);
            setSelectedDomainId(null);
            setSelectedDomain(null);
            setLoading(false);
        }
    };

    const handleDeleteCancel = () => {
        setShowDeleteModal(false);
        setShowVerifyDomainModal(true);
    };

    const handleSearchKeyPress = (e) => {
        if (e.key === 'Escape') {
            setSearchQuery('');
        }
    };

    const filteredDomains = domains.filter(domain =>
        domain.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="page page-narrow domains">
                <div className="section-title">Domains</div>
                <div className="card section-card">
                    <div className="card-body">
                        <Button variant="primary" onClick={() => setShowAddDomainModal(true)} className="mb-3">Add Domain</Button>
                        <Form.Control
                            type="text"
                            placeholder="Search domains..."
                            className="mb-3"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={handleSearchKeyPress}
                        />
                        {filteredDomains.length > 0 ? (
                            <>
                                <div className="data-list mb-3">
                                    {filteredDomains.map(domain => (
                                        <div key={domain.id} className="data-list-item clickable" onClick={() => handleVerifyClick(domain)}>
                                            <div className="row">
                                                <div className="col">
                                                    <span>{domain.name}</span>
                                                </div>
                                                <div className="col d-flex justify-content-end">
                                                        {domain.isVerified ? <Badge bg="success" className="me-2">Verified</Badge> : <Badge bg="secondary" className="me-2">Unverified</Badge>}
                                                        {domain.isAVerified ? <Badge bg="success" className="me-2">A</Badge> : <Badge bg="secondary" className="me-2">A</Badge>}
                                                        {domain.isPublic ? <Badge bg="secondary" className="me-2">Public</Badge> : null}
                                                        {domain.sslStatus ? <Badge bg="success" className="me-2">SSL</Badge> : null}
                                                        {domain.configsStatus === 'CREATED' ? <Badge bg="warning" className="me-2">Pending</Badge> : null}
                                                        {domain.configsStatus === 'FAILED' ? <Badge bg="danger" className="me-2">Failed</Badge> : null}
                                                        {domain.configsStatus === 'SUCCESS' ? <Badge bg="success" className="me-2">Success</Badge> : null}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : <p>No domains available.</p>}
                        <div className="data-list-limits">Domains: {limits ? limits.current_domains : null} / {limits ? limits.max_domains : null}</div>
                    </div>
                </div>
                <div className="card section-card">
                    <div className="card-body">
                        <div className="card-title">Buy a domain</div>
                        <p>You can buy a domain from any provider you like, I suggest the following providers:</p>
                        <div className="suggested-partner-list">
                            <a href="https://vclck.com/bluehost?utm_source=tinyrebrand-app&utm_medium=domains" target="_blank"><img src={bluehostLogo} title="bluehost" /></a>
                            <a href="https://vclck.com/cloudflare?utm_source=tinyrebrand-app&utm_medium=domains" target="_blank"><img src={cloudflareLogo} title="cloudflare" /></a>
                            <a href="https://vclck.com/hostinger?utm_source=tinyrebrand-app&utm_medium=domains" target="_blank"><img src={hostingerLogo} title="hostinger" /></a>
                            <a href="https://vclck.com/namecheap?utm_source=tinyrebrand-app&utm_medium=domains" target="_blank"><img src={namecheapLogo} title="namecheap" /></a>
                        </div>
                        <div className="affiliate-disclaimer">
                            Disclaimer: The links above are affiliate links, which means that I earn a commission if you click and buy a domain. This is at no additional cost to you.
                        </div>
                    </div>
                </div>
            </div>
            <AddDomainModal
                showModal={showAddDomainModal}
                onCancel={() => setShowAddDomainModal(false)}
                refreshList={loadDomainsData}
            />
            <VerifyDomainModal
                showModal={showVerifyDomainModal}
                onCancel={() => setShowVerifyDomainModal(false)}
                onDelete={handleDeleteClick}
                selectedId={selectedDomainId}
                domain={selectedDomain}
                refreshList={loadDomainsData}
            />
            <DeleteConfirmationModal
                showModal={showDeleteModal}
                onCancel={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                itemLabel="domain"
            />
        </>
    );
}

export default Domains;
